import { Popconfirm, Table } from "antd";
import React, { useEffect, useState } from "react";
import createAxiosInstance from "../../utils/axios.config";

export default function ContactListTable(props) {
  const client = createAxiosInstance();
  const { setClientNumbers } = props;

  const [dataSource, setDataSource] = useState();
  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      editable: true,
    },
    {
      title: "Apellido",
      dataIndex: "apellido",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Número telefónico",
      dataIndex: "numero_celular",
    },
    {
      title: "Fecha Nacimiento",
      dataIndex: "fechaNacimiento",
    },
    {
      title: "Género",
      dataIndex: "genero",
    },
    {
      title: "Acción",
      dataIndex: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="¿Desea quitar el contacto de la lista?"
            onConfirm={() => handleDelete(record.key)}
          >
            <span className="fe fe-trash fe-12 mr-2"></span>
            <a>Quitar</a>
          </Popconfirm>
        ) : null,
    },
  ];
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    client
      .get("contact")
      .then((response) => {
        response.data.map((item) => {
          item.key = item.id_destinatario;
          const dateObj = new Date(item.fechaNacimiento);

          const year = dateObj.getUTCFullYear();
          const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
          const day = String(dateObj.getUTCDate()).padStart(2, "0");
          const hours = String(dateObj.getUTCHours()).padStart(2, "0");
          const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");
          const seconds = String(dateObj.getUTCSeconds()).padStart(2, "0");

          let formattedDate = `${year}-${month}-${day}`;
          if(formattedDate === "NaN-NaN-NaN") formattedDate= ""

          item.fechaNacimiento=formattedDate
        });
        setDataSource(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if(setClientNumbers){
        setClientNumbers(selectedRowKeys);
      }   
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div>
      <Table
        loading={loading}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
}
