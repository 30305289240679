import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import ivrIcon from "../../images/chatbot.png";
import createAxiosInstance from "../../utils/axios.config";
import { Row, Stack } from "react-bootstrap";
function IVRList(props) {
  const client = createAxiosInstance();
  const { setIvrSelected, setIvrSelectedName } = props;
  const [ivrs, setIvrs] = useState();
  const [ivrList, setIvrList] = useState();
  const [indexChecked, setIndexChecked] = useState();
  const [flagModel, setFlagModel] = useState(false);

  useEffect(() => {
    console.log(localStorage.getItem("pbxmodel"));
    if (localStorage.getItem("pbxmodel") === "P-Series") {
      setFlagModel(true);
    }
    console.log(flagModel);
  }, [flagModel]);

  useEffect(() => {
    console.log(ivrs);
    if (ivrs !== null && ivrs !== undefined) {
      if (localStorage.getItem("pbxmodel") === "P-Series") {
        console.log("Asignando:", ivrs.data);
        setIvrList(ivrs.data);
        console.log("IVRLIST:", ivrList);
      } else {
        console.log("Asignando:", ivrs.ivr);
        setIvrList(ivrs.ivr);
        console.log("IVRLIST:", ivrList);
      }
    }
  }, [ivrs]);

  useEffect(() => {
    const pbxModel = localStorage.getItem("pbxmodel");
    console.log("modelo:", pbxModel);
    console.log("token:", sessionStorage.getItem("token"));
    client
      .get(`ivr?pbxmodel=${pbxModel}`)
      .then((result) => {
        console.log("Data conseguida!");
        console.log("Result: ", result);
        if (
          result.data.status === "Success" ||
          result.data.errmsg === "SUCCESS"
        ) {
          setIvrs(result.data);
        }
      })
      .catch((err) => {
        console.log("Hubo un error:", err.menssage);
      });
  }, []);

  return (
    <Stack
      direction="horizontal"
      style={{ overflowY: "auto", whiteSpace: "nowrap" }}
    >
      <br />
      {flagModel
        ? ivrList
          ? ivrList.map((item, index) => {
              return (
                <label className="text-center ml-5 mb-0" key={uuidv4()}>
                  <input
                    type="radio"
                    name="ivr"
                    value={item.number}
                    checked={indexChecked === index ? true : false}
                    onChange={(e) => {
                      setIndexChecked(index);
                      setIvrSelected(item.number);
                      setIvrSelectedName(item.name);
                    }}
                    required
                  />
                  <img src={ivrIcon} alt="Option 1" />
                  <p>{item.name}</p>
                </label>
              );
            })
          : ""
        : ivrList
        ? ivrList.map((item, index) => {
            return (
              <label className="text-center ml-5 mb-0" key={uuidv4()}>
                <input
                  type="radio"
                  name="ivr"
                  value={item.ivrnumber}
                  checked={indexChecked === index ? true : false}
                  onChange={(e) => {
                    setIndexChecked(index);
                    setIvrSelected(item.ivrnumber);
                    setIvrSelectedName(item.ivrname);
                  }}
                  required
                />
                <img src={ivrIcon} alt="Option 1" />
                <p>{item.ivrname}</p>
              </label>
            );
          })
        : ""}
    </Stack>
  );
}
export default IVRList;
