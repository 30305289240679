import { gql } from "@apollo/client"
export const USER_REGISTER = gql`
mutation registrar_usuario($nombre:String!,$nombre_de_usuario:String!,$telefono:Int!,$contrasena:String!) {
    registrar_usuario(nombre:$nombre,telefono:$telefono, nombre_de_usuario: $nombre_de_usuario,
      contrasena: $contrasena) {
        estado
      data {
        token
        usuario {
          rol_usuario {
            nombre_rol
          }
          nombre
          telefono
          nombre_de_usuario
          fecha_creacion
          fecha_actualizacion
          estado
        }
      }
      error
    }
  }
`