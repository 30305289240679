import React, { useEffect, useState } from "react";
import createAxiosInstance from "../../utils/axios.config";
//import { useUpdatePbx, useGetRoutAndExtension } from "../../data/pbx/custom-hooks";
import { Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import swal from "sweetalert";

export default function EditPbxFrom() {
  const client = createAxiosInstance();
  //const [updatePBX, { loading, data }] = useUpdatePbx();
  //const [getRoutExtension,dataPBX] = useGetRoutAndExtension()
  const [ruta, setRuta] = useState("");
  const [extension, setExtension] = useState("");

  useEffect(
    () => {
      /*if(data!==undefined){
    if(data.actualizar_pbx.estado===200){
        swal("Registro exitoso","los datos se registraron correctamente",'success')
    }
    else if(data.actualizar_pbx.estado===500){
        swal("Registro fallido",data.actualizar_pbx.error,'error')
    }
    }*/
    },
    [
      /*data*/
    ]
  );
  useEffect(() => {
    client
      .get("privatebranchexchange")
      .then((response) => {
        setExtension(response.data.extension);
        setRuta(response.data.ruta);
        //establecer la extension y la ruta
      })
      .catch((err) => {});
    /*getRoutExtension({variables:{
        token:sessionStorage.getItem('token')
    }})*/
  }, []);

  useEffect(
    () => {
      /*if(dataPBX.data!==undefined){
        if(dataPBX.data.obtener_pbx.estado===200){
            setRuta(dataPBX.data.obtener_pbx.data.ruta)
            setExtension(dataPBX.data.obtener_pbx.data.extension)
        }
    }*/
    },
    [
      /*dataPBX.data*/
    ]
  );

  return (
    <Formik
      initialValues={{ extension: extension, ruta: ruta }}
      validate={(values) => {
        const errors = {};
        if (!extension) {
          errors.extension = "Campo requerido";
        } else if (!/^[0-9]+$/.test(extension)) {
          errors.extension = "El valor de la extension debe ser numeral";
        }
        if (!ruta) {
          errors.ruta = "Campo requerido";
        } else if (!/^[0-9]+$/.test(ruta)) {
          errors.ruta = "El valor de la ruta debe ser numeral";
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        client({
          url: "/privatebranchexchange",
          method: "PATCH",
          data: {
            extension: extension,
            ruta: ruta,
          },
        })
          .then((res) => {
            swal("Datos establecidos correctamente","","success")
          })
          .catch((e) => {
            swal("Error","Ocurrió un error, por favor inténtelo mas tarde","error")
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Numero de la extensión</Form.Label>
            <Form.Control
              required
              type="text"
              name="extension"
              onChange={(e) => setExtension(e.target.value)}
              onBlur={handleBlur}
              value={extension}
              isValid={touched.extension && !errors.extension}
              isInvalid={!!errors.extension}
            />
            <Form.Control.Feedback>Correcto</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              {errors.extension}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Numero de la ruta</Form.Label>
            <Form.Control
              required
              type="text"
              name="ruta"
              onChange={(e) => setRuta(e.target.value)}
              onBlur={handleBlur}
              value={ruta}
              isValid={touched.ruta && !errors.ruta}
              isInvalid={!!errors.ruta}
            />
            <Form.Control.Feedback>Correcto</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              {errors.ruta}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <br />
            <Button type="submit">Guardar Datos</Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
}
