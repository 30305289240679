import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavSidebar from "../NavSidebar/NavSidebar";
import { Outlet } from "react-router-dom";

function MainPage() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!sessionStorage.getItem("token")) navigate("/login");
  }

  ,[]);
  return sessionStorage.getItem("token") ? (
    <>
      <NavSidebar />
      <main role="main" className="main-content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12">
              <Outlet />
            </div>
          </div>
        </div>
      </main>
    </>
  ) : (
    navigate("/login")
  );
}
export default MainPage;
