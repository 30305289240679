import { gql } from "@apollo/client"
export const LOGIN = gql`
query iniciar_sesion($email:String!,$password:String!){
    iniciar_sesion(nombre_de_usuario:$email, contrasena:$password){
        estado
        data{
            token
            usuario{
                nombre_de_usuario
                estado
                rol_usuario{
                    id_rol_usuario
                }
            }
        }
        error
    }
}`