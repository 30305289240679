import { useEffect, useState } from "react";
import logo from "../../images/nux.webp";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./Login.css";
import { Amplify, Auth } from "aws-amplify";
import config from "../../aws-exports";
import RegisterSuccessfully from "../RegistrarUsuario/RegisterSuccessfully";
Amplify.configure(config);

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState({ state: false, message: "" });
  const [confirmedUser, setConfirmedUser] = useState(true);
  const [cognitoUserName, setCognitoUserName] = useState("");

  const location = useLocation();

  const autoSignIn = async () => {
    try {
      console.log("Intentando loggear auto");
      const searchParams = new URLSearchParams(location.search);
      const email = searchParams.get("email");
      const password = decodeURIComponent(searchParams.get("password"));
      console.log("variables:", email, password);
      var user = await Auth.signIn(email.trim(), password.trim());
      var token = user.signInUserSession;
      if (token != null) {
        setError({ state: false, message: null });
        sessionStorage.setItem("token", token.idToken.jwtToken);
        console.log(token);
        navigate("/dashboard");
        console.log(token.idToken.jwtToken);
      }
    } catch (error) {
      // Manejar errores de inicio de sesión automático
      console.error("Error al iniciar sesión automáticamente:", error);
    }
  };

  useEffect(() => {
    autoSignIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buttonHandler = async () => {
    try {
      var user = await Auth.signIn(email.trim(), password.trim());
      var token = user.signInUserSession;
      if (token != null) {
        setError({ state: false, message: null });
        navigate("/dashboard");
        sessionStorage.setItem("token", token.idToken.jwtToken);
        console.log(token.idToken.jwtToken);
      }
    } catch (err) {
      setError({ state: true, message: err.message });
      if (err.message === "User is not confirmed.") {
        //const userdata = await Auth.resendSignUp(email)
        console.log(user);
        setCognitoUserName(email);
        setError({ state: false, message: null });
        //const us=await Auth.resendSignUp(email)
        setConfirmedUser(false);
      }
    }
  };
  return confirmedUser ? (
    <>
      <div className="wrapper vh-100">
        <nav
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            margin: "10px",
          }}
        >
          <button
            className="btn btn-lg btn-primary btn-block"
            type="button"
            style={{ width: "auto" }}
            onClick={() => {
              navigate("/registry");
            }}
          >
            Registrarse
          </button>
        </nav>
        <div className="login">
          <form
            className="col-lg-3 col-md-4 col-10 mx-auto text-center"
            onSubmit={(event) => event.preventDefault()}
          >
            <img className="" src={logo} width="100%" alt="" />
            <div className="form-group">
              <label className="sr-only">Correo Electrónico</label>
              <input
                type="email"
                id="inputEmail"
                name="email"
                value={email}
                className="form-control form-control-lg"
                placeholder="Correo Electrónico"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label className="sr-only">Contraseña</label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                className="form-control form-control-lg"
                placeholder="Contraseña"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {error.state ? (
              <div className="alert alert-danger" role="alert">
                <span className="fe fe-minus-circle fe-16 mr-2"></span>
                {error.message}
              </div>
            ) : (
              ""
            )}
            <button
              className="btn btn-lg btn-primary btn-block"
              type="submit"
              onClick={buttonHandler}
            >
              Iniciar Sesión
            </button>
            <p className="mt-5 mb-3 text-muted">
              Nuxway Technology © {new Date().getFullYear()}
            </p>
          </form>
        </div>
      </div>
    </>
  ) : (
    <RegisterSuccessfully
      email={email}
      cognitoUserName={cognitoUserName}
      setConfirmedUser={setConfirmedUser}
    />
  );
}
export default Login;
