import React from "react";
import { Formik } from "formik";
import { Form, FormControl, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import createAxiosInstance from "../../utils/axios.config";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

export default function CrearContacto() {
  const client = createAxiosInstance()
  const navigate=useNavigate()
  return (
    <div className="col-12 col-xl-10">
      <Formik
        initialValues={{
          email: "",
          firstname: "",
          lastname: "",
          cellNumber: "",
          date: "",
          gender: "",
        }}
        validate={(values) => {
          const errors = {};
          //validacion del nombre de contacto
          if (!values.firstname) {
            errors.firstname = "Campo requerido";
          } else if (!/^[A-Za-z\s]+$/g.test(values.firstname)) {
            errors.firstname = "formato de nombre incorrecto";
          }
          //validacion del  apellido de contacto
          if (!values.lastname) {
            errors.lastname = "Campo requerido";
          } else if (!/^[A-Za-z\s]+$/g.test(values.lastname)) {
            errors.lastname = "formato de apellido incorrecto";
          }
          //validacion de numero de contacto
          if (!values.cellNumber) {
            errors.cellNumber = "Campo requerido";
          } else if (!/(6|7)([0-9]){7}/.test(values.cellNumber)) {
            errors.cellNumber = "Numero de celular inválido";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          client
            .post("contact", {
              nombre: values.firstname,
              apellido: values.lastname,
              email: values.email,
              numero_celular: values.cellNumber,
              fechaNacimiento: values.date,
              genero: values.gender,
            })
            .then((response) => {
              navigate("/contact-list")
            })
            .catch((error) => {
              swal("Error al crear el contacto",'ocurrio un error, por favor intentelo mas tarde', 'error')
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <>
            <div className="row">
              <div className="col">
                <h2 className="h3 mb-0 page-title">Agregar contacto</h2>
              </div>
            </div>
            <Form noValidate onSubmit={handleSubmit}>
              <hr className="my-4" />
              <Row>
                <Form.Group className="col-md-6">
                  <Form.Label htmlFor="firstname">Nombre Completo</Form.Label>
                  <Form.Control
                    type="text"
                    id="firstname"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstname}
                    isValid={touched.firstname && !errors.firstname}
                    isInvalid={!!errors.firstname}
                  />

                  <Form.Control.Feedback></Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.firstname}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-6">
                  <Form.Label htmlFor="lastname">Apellidos</Form.Label>
                  <Form.Control
                    type="text"
                    id="lastname"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastname}
                    isValid={touched.lastname && !errors.lastname}
                    isInvalid={!!errors.lastname}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.lastname}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="col-md-8">
                  <Form.Label htmlFor="email">Correo electrónico</Form.Label>
                  <Form.Control
                    type="email"
                    id="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    isValid={touched.email && !errors.email}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-4">
                  <Form.Label htmlFor="cellNumber">
                    Número de teléfono celular
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    id="cellNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cellNumber}
                    isValid={touched.cellNumber && !errors.cellNumber}
                    isInvalid={!!errors.cellNumber}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.cellNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="col-md-4">
                  <Form.Label htmlFor="date">fecha de nacimiento</Form.Label>
                  <FormControl
                    id="date"
                    type="date"
                    name="date"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.date}
                    isValid={touched.date && !errors.date}
                    isInvalid={!!errors.date}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.date}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-4">
                  <Form.Label htmlFor="gender">Género</Form.Label>
                  <Form.Select
                    id="gender"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.gender}
                    isValid={touched.gender && !errors.gender}
                    isInvalid={!!errors.gender}
                  >
                    <option defaultValue></option>
                    <option value="Masculino">Masculino</option>
                    <option value="Femenino">Femenino</option>
                  </Form.Select>
                  <Form.Control.Feedback></Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.gender}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <hr className="my-4" />
              {/*<h5 className="mb-2 mt-4">Datos Adicionales</h5>
              <Row>
                <Form.Group className="col-md-6">
                  <Form.Label>Etiqueta</Form.Label>
                  <Form.Control type="text" id={uuidv4()} className="form-control" />
                </Form.Group>
                <Form.Group className="form-group col-md-6">
                  <Form.Label >Valor</Form.Label>
                  <Form.Control
                    type="text"
                    id={uuidv4()}
                    className="form-control" />
                </Form.Group>
        </Row>*/}
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary"
              >
                <span className="fe fe-save fe-12 mr-2"></span>Guardar
              </button>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}
