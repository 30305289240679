export const SidebarData = [
    /*{
        groupname: "Administracion",
        items: [
            {
                nameItem: "Administrar Centrales",
                subItems:null,
                icon: "fe fe-hard-drive fe-16",
                indexPage:4
            },
            {
                nameItem: "Clientes",
                subItems:null,
                icon: "fe fe-users fe-16",
                indexPage:6
            },
            {
                nameItem: "Nuevo Cliente",
                subItems:null,
                icon: "fe fe-user-plus fe-16",
                indexPage:6
            },
        ]
    },*/
    {
        groupname: "Inicio",
        items:[
            {
                nameItem: "Dashboard",
                subItems: null,
                icon: "fe fe-pie-chart fe-16",
                route: "dashboard"
            },
            {
                nameItem: "Central telefónica",
                subItems: null,
                icon: "fe fe-hard-drive fe-16",
                route:"pbx-management"
            }
        ],
    },
    {
        groupname: "Llamadas",
        items: [
            {
                nameItem: "Lista de Contactos",
                icon: "fe fe-book fe-16",
                route:"contact-list"
            },
            {
                nameItem: "Crear Campañas",
                icon: "fe fe-file-text fe-16",
                route:"create-campaign"
            },
            {
                nameItem: "Realizar Llamadas",
                icon: "fe fe-phone-forwarded fe-16",
                route: "select-campaign"
            }
        ]
    },
    /*{
        groupname: "Servicio",
        items: [
            {
                nameItem: "Tarjetas SIM",
                subItems:null,
                icon: "fe fe-credit-card fe-16"
            },
            {
                nameItem: "IVRs",
                subItems:null,
                icon:"fe fe-message fe-16"
            },
            {
                nameItem:"Trunk SIP",
                subItems:null,
                icon:"fe fe-message fe-16"
            }
        ]
    }   ,*/
]