import React from "react";
import { useState, useEffect } from "react";
import createAxiosInstance from "../utils/axios.config";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

export default function CallProcessor(props) {
  const client = createAxiosInstance();
  const { destinatarios, setDestinatarios, id_campania, numeroIvr } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(true); // Inicia pausado
  const [isCancelled, setIsCancelled] = useState(false);
  const [idCampaignLaunched, setIdCampaignLaunched] = useState(0);
  const navigate = useNavigate();

  const [intervalValue, setIntervalValue] = useState(10);

  const handleIntervalChange = (event) => {
    setIntervalValue(event.target.value);
  };

  const makeCall = (id_dest, phoneNumber) => {
    const model = localStorage.getItem("pbxmodel");
    //Realizamos la llamada telefónica desde la API
    client
      .post("call", {
        numero_ivr: `${numeroIvr}`,
        destinatario: phoneNumber,
        id_lanzar_campania: idCampaignLaunched,
        id_destinatario: id_dest,
        model: model,
      })
      .then((response) => {
        //actualizamos los datos de la lista de contactos con los resultados de las llamadas
        if (response.data.status === "Success") {
          const destAux = destinatarios.map((dest) => {
            if (id_dest === dest.id_destinatario) {
              dest.estado = "Llamada Realizada";
            }
            return dest;
          });
          setDestinatarios([...destAux]);
        } else {
          const destAux = destinatarios.map((dest) => {
            if (id_dest === dest.id_destinatario) {
              dest.estado = "Error: " + response.data.errno;
            }
            return dest;
          });
          setDestinatarios([...destAux]);
        }
      })
      .catch((err) => {});
  };

  const processCalls = () => {
    if (currentIndex < destinatarios.length) {
      if (!isPaused && !isCancelled) {
        makeCall(
          destinatarios[currentIndex].id_destinatario,
          destinatarios[currentIndex].numero_celular
        );
        setCurrentIndex(currentIndex + 1);
      }
    } else {
      setIsPaused(true);
      swal("La campaña se ha completado", {
        icon: "success",
      });
    }
  };

  useEffect(() => {
    if (!isPaused && !isCancelled) {
      const interval = setInterval(processCalls, intervalValue*1000); // Llamar cada 30 segundos

      return () => {
        clearInterval(interval);
      };
    }
  }, [currentIndex, isPaused, isCancelled]);

  const pauseCalls = () => {
    setIsPaused(true);
  };

  const resumeCalls = () => {
    setIsPaused(false);
  };

  const cancelCalls = () => {
    swal({
      title: "Finalizar campaña",
      text: "¿Esta seguro de finalizar la campaña?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setIsCancelled(true);
        setIsPaused(true);
        navigate("/select-campaign");
        /*swal("Poof! Your imaginary file has been deleted!", {
          icon: "success",
        });*/
      }
    });
  };

  const startCalls = () => {
    client
      .post("campaignlaunched", { id_campania: id_campania })
      .then((response) => {
        setIdCampaignLaunched(response.data.id_lanzar_campania);
        setIsPaused(false);
        setIsCancelled(false);
      })
      .catch((err) => {
        swal("Ocurrió un error", `Por favor inténtelo mas tarde`, "error");
      });
  };

  return (
    <div className="text-right d-flex flex-wrap justify-content-end align-items-center">
      <form className="mb-2 mr-sm-1 d-flex">
        <input
          type="number"
          className="btn form-control mr-1 bg-white border border-dark"
          value={intervalValue}
          onChange={handleIntervalChange}
          min="1"
        />
      </form>
      <button
        type="button"
        className="btn mb-2 btn-success text-white mr-1"
        disabled={idCampaignLaunched !== 0 ? true : false}
        onClick={startCalls}
      >
        <span className="fe fe-phone-forwarded fe-16 mr-1"></span>
        Iniciar Llamadas
      </button>
      <button
        className="btn mb-2 btn-warning text-white mr-1"
        disabled={isPaused}
        onClick={pauseCalls}
      >
        <span className="fe fe-pause fe-16 mr-1"></span>
        Pausar
      </button>
      <button
        className="btn mb-2 btn-info text-white mr-1"
        disabled={!isPaused}
        onClick={resumeCalls}
      >
        <span className="fe fe-play fe-16 mr-1"></span>
        Reanudar
      </button>

      <button
        className="btn mb-2 btn-danger text-white mr-1"
        onClick={cancelCalls}
      >
        <span className="fe fe-stop-circle fe-16 mr-1"></span>
        Cancelar
      </button>
    </div>
  );
}
