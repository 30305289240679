import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContactListTable from "../../components/Table/ContactListTable";

export default function ListarContactos() {
  const navigate = useNavigate();

  return (
    <>
      <div className="row mb-5">
        <div className="col">
          <h2 className="page-title">Lista de contactos</h2>
        </div>
        <div className="col-auto">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => navigate("/new-contact")}
          >
            <span className="fe fe-plus fe-12 mr-2"></span>Nuevo contacto
          </button>
        </div>
      </div>

      <div className="card shadow">
        <div className="card-body">
          <ContactListTable />
        </div>
      </div>
    </>
  );
}
