import React from "react";
import { Popconfirm, Table } from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import createAxiosInstance from "../../utils/axios.config";

export default function LaunchedCampaigns() {
  const client = createAxiosInstance();
  const [dataSource, setDataSource] = useState();
  const navigate = useNavigate();
  const columns = [
    {
      title: "Nombre de la campaña",
      dataIndex: "nombre_campania",
      editable: true,
    },
    {
      title: "Nombre del IVR",
      dataIndex: "nombre_ivr",
    },
    {
      title: "Número del IVR",
      dataIndex: "numero_ivr",
    },
    {
      title: "Fecha de lanzamiento",
      dataIndex: "fecha_creacion",
    },
    {
      title: "Acción",
      dataIndex: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <button
            type="button"
            className="btn btn-success "
            onClick={() => {
              navigate(`/dashboard/${record.key}`);
            }}
          >
            <span className="fe fe-eye fe-12 mr-2"></span>Ver detalles
          </button>
        ) : null,
    },
  ];
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    client
      .get("reports/capaignslaunched")
      .then((response) => {
        response.data.map((item) => {
          item.key = item.id_lanzar_campana;

          //formatear la hora
          const dateObj = new Date(item.fecha_creacion);

          const year = dateObj.getUTCFullYear();
          const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
          const day = String(dateObj.getUTCDate()).padStart(2, "0");
          const hours = String(dateObj.getUTCHours()).padStart(2, "0");
          const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");
          const seconds = String(dateObj.getUTCSeconds()).padStart(2, "0");

          const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

          item.fecha_creacion=formattedDate
        });
        setDataSource(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <Table loading={loading} dataSource={dataSource} columns={columns} />
    </div>
  );
}
