import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import pbxImage from "../../images/pbxcloud_syp.jpg";
//import { useRegisterPbx, useGetPbx } from "../../data/pbx/custom-hooks";
import swal from "sweetalert";
import createAxiosInstance from "../../utils/axios.config";
export default function RegistryPbxFrom() {
  const client = createAxiosInstance();
  //const [registryPbx, { loading, data }] = useRegisterPbx();
  //const [getPBX, getpbxData] = useGetPbx();
  const [pbxData, setPbxData] = useState();
  const [nUrl, setUrl] = useState("");
  const [nApi, setNApi] = useState("");
  const [nPassword, setNPassword] = useState("");
  const [pbxStatus, setPbxStatus] = useState(0);
  const [pbxModel, setPbxModel] = useState("S-Cloud");

  /*useEffect(() => {
    if (pbxData) {
      if (pbxData.estado === 1) {
        setPbxStatus(pbxData.estado)
        swal("Registro exitoso", "Se conectó con éxito ", "success");
      } else {
        swal("Error al registrar", "", "error");
      }
    }
  }, [pbxData]);*/
  useEffect(() => {
    client
      .get("privatebranchexchange")
      .then((response) => {
        setPbxData(response.data);
      })
      .catch((err) => {});
    /*getPBX({
      variables: {
        token: sessionStorage.getItem("token"),
      },
    });*/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pbxData !== undefined) {
      setUrl(pbxData.url);
      setNApi(pbxData.nombre_usuario);
      setNPassword(pbxData.contrasena);
      setPbxStatus(pbxData.estado);
      if (localStorage.getItem("pbxmodel") !== undefined) {
        setPbxModel(localStorage.getItem("pbxmodel"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pbxData]);

  return (
    <Formik
      initialValues={{ url: nUrl, nombre_usuario: nApi, contrasena: nPassword }}
      validate={(values) => {
        const errors = {};

        //validacion del url
        if (!nUrl) {
          errors.url = "Campo requerido";
        } else if (
          !/[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi.test(
            nUrl
          )
        ) {
          errors.url = "Formato de url invalido";
        }
        //validaciones del nombre_usuario
        if (!nApi) {
          errors.nombre_usuario = "Campo requerido";
        }
        if (!nPassword) {
          errors.contrasena = "Campo requerido";
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        client
          .post("privatebranchexchange", {
            url: nUrl,
            nombre_usuario: nApi,
            contrasena: nPassword,
            model: pbxModel,
          })
          .then((response) => {
            setPbxData(response.data);
            console.log(pbxModel);
            localStorage.setItem("pbxmodel", pbxModel);
            console.log(response);
            console.log(response.data);
            swal("Registro exitoso", "Se conectó con éxito ", "success");
          })
          .catch((err) => {
            swal("Error al registrar", err.response.data, "error");
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <div className="card shadow mb-4">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>Direccion URL</Form.Label>
                    <Form.Control
                      required
                      type="Text"
                      name="url"
                      onChange={(e) => setUrl(e.target.value)}
                      onBlur={handleBlur}
                      value={nUrl}
                      isValid={touched.url && !errors.url}
                      isInvalid={!!errors.url}
                    />
                    <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.url}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Nombre de usuario de la API</Form.Label>
                    <Form.Control
                      required
                      type="Text"
                      name="nombre_usuario"
                      onChange={(e) => setNApi(e.target.value)}
                      onBlur={handleBlur}
                      value={nApi}
                      isValid={touched.nombre_usuario && !errors.nombre_usuario}
                      isInvalid={!!errors.nombre_usuario}
                    />
                    <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.nombre_usuario}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Contraseña de la API</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      name="contrasena"
                      onChange={(e) => setNPassword(e.target.value)}
                      onBlur={handleBlur}
                      value={nPassword}
                      isValid={touched.contrasena && !errors.contrasena}
                      isInvalid={!!errors.contrasena}
                    />
                    <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.contrasena}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Modelo de PBX</Form.Label>
                    <Form.Select
                      size="lg"
                      className={`form-control ${
                        touched.modelo_pbx && errors.modelo_pbx
                          ? "is-invalid"
                          : ""
                      }`}
                      onChange={(e) => setPbxModel(e.target.value)}
                      onBlur={handleBlur}
                      value={pbxModel}
                    >
                      <option value="S-Cloud" selected>
                        S-Cloud
                      </option>
                      <option value="P-Series">P-Series</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <img
                    src={pbxImage}
                    style={{ width: "400px" }}
                    alt="yeastarSerieS"
                  />
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <small>
                    <span
                      className={`dot dot-lg ${
                        pbxStatus === 1 ? "bg-success" : "bg-danger"
                      } mr-1`}
                    ></span>
                    {pbxStatus === 1
                      ? "Online"
                      : pbxStatus === 2
                      ? "Offline"
                      : "Unregistered"}
                  </small>
                </div>
                <div className="col-auto">
                  <Button type="submit" variant="primary">
                    Conectar
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
